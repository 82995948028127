body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ant-layout {
    /* flex: 0 0 400px !important; */
    min-width: 200px !important;
}

.ant-table-cell-row-hover {
    background-color: inherit !important;
    color: inherit !important;
}

.ant-menu-item.ant-menu-item-only-child{
    padding-left: 0px !important;
}